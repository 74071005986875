import React, { useState } from "react";

const AddStore = () => {
  const [storeDetails, setStoreDetails] = useState({
    name: "",
    logo: "",
    gstNumber: "",
    website: "",
    description: "",
    categories: "",
    contactNumber: "",
    email: "",
    googleAddress: "",
    openingTime: "",
    closingTime: "",
    socialHandle: "",
  });

  const commonCategories = [
    "Fashion",
    "Restaurant",
    "Electronics",
    "Grocery",
    "Home Decor",
  ];

  const timeOptions = [
    "12:00 AM",
    "1:00 AM",
    "2:00 AM",
    "3:00 AM",
    "4:00 AM",
    "5:00 AM",
    "6:00 AM",
    "7:00 AM",
    "8:00 AM",
    "9:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "1:00 PM",
    "2:00 PM",
    "3:00 PM",
    "4:00 PM",
    "5:00 PM",
    "6:00 PM",
    "7:00 PM",
    "8:00 PM",
    "9:00 PM",
    "10:00 PM",
    "11:00 PM",
  ];

  const [existingStores, setExistingStores] = useState([
    {
      id: 1,
      name: "RK Sarees",
      logo: "https://example.com/rksarees-logo.jpg",
      gstNumber: "1234567890",
      website: "https://www.rksarees.com",
      description: "Leading saree store in Patiala.",
      categories: "Fashion",
      contactNumber: "9876543210",
      email: "rksarees@example.com",
      googleAddress: "4/56-2 22no. phatak, Patiala, Punjab",
      openingTime: "9:00 AM",
      closingTime: "7:00 PM",
      socialHandle: "@rksarees",
    },
    {
      id: 2,
      name: "ElectroGadget Hub",
      logo: "https://example.com/electrogadget-logo.jpg",
      gstNumber: "0987654321",
      website: "https://www.electrogadgethub.com",
      description: "Your one-stop shop for electronic gadgets.",
      categories: "Electronics",
      contactNumber: "8765432109",
      email: "electrogadget@example.com",
      googleAddress: "123 Tech Street, Gadget City",
      openingTime: "10:00 AM",
      closingTime: "8:00 PM",
      socialHandle: "@electrogadgethub",
    },
  ]);

  const handleClickStore = (selectedStore) => {
    setStoreDetails(selectedStore);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStoreDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (storeDetails.name && storeDetails.categories) {
      const newStore = {
        id: existingStores.length + 1,
        name: storeDetails.name,
        logo: storeDetails.logo,
        gstNumber: storeDetails.gstNumber,
        website: storeDetails.website,
        description: storeDetails.description,
        categories: storeDetails.categories,
        contactNumber: storeDetails.contactNumber,
        email: storeDetails.email,
        googleAddress: storeDetails.googleAddress,
        openingTime: storeDetails.openingTime,
        closingTime: storeDetails.closingTime,
        socialHandle: storeDetails.socialHandle,
      };

      setExistingStores((prevStores) => [...prevStores, newStore]);

      setStoreDetails({
        name: "",
        logo: "",
        gstNumber: "",
        website: "",
        description: "",
        categories: "",
        contactNumber: "",
        email: "",
        googleAddress: "",
        openingTime: "",
        closingTime: "",
        socialHandle: "",
      });
    } else {
      alert("Please fill in all the store details.");
    }
  };

  return (
    <div className="container mx-auto p-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-purple-800 mb-4">
          Add New Store
        </h1>
        <form onSubmit={handleSubmit} className="max-w-lg">
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-sm font-bold mb-2 text-purple-800"
            >
              Store Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={storeDetails.name}
              onChange={handleChange}
              className="w-full border p-2"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="logo"
              className="block text-sm font-bold mb-2 text-purple-800"
            >
              Logo URL
            </label>
            <input
              type="text"
              id="logo"
              name="logo"
              value={storeDetails.logo}
              onChange={handleChange}
              className="w-full border p-2"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="gstNumber"
              className="block text-sm font-bold mb-2 text-purple-800"
            >
              GST Number
            </label>
            <input
              type="text"
              id="gstNumber"
              name="gstNumber"
              value={storeDetails.gstNumber}
              onChange={handleChange}
              className="w-full border p-2"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="website"
              className="block text-sm font-bold mb-2 text-purple-800"
            >
              Website
            </label>
            <input
              type="text"
              id="website"
              name="website"
              value={storeDetails.website}
              onChange={handleChange}
              className="w-full border p-2"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="description"
              className="block text-sm font-bold mb-2 text-purple-800"
            >
              Description
            </label>
            <textarea
              id="description"
              name="description"
              value={storeDetails.description}
              onChange={handleChange}
              className="w-full border p-2"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="categories"
              className="block text-sm font-bold mb-2 text-purple-800"
            >
              Categories
            </label>
            <select
              id="categories"
              name="categories"
              value={storeDetails.categories}
              onChange={handleChange}
              className="w-full border p-2"
            >
              <option value="" disabled>
                Select a category
              </option>
              {commonCategories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label
              htmlFor="contactNumber"
              className="block text-sm font-bold mb-2 text-purple-800"
            >
              Contact Number
            </label>
            <input
              type="text"
              id="contactNumber"
              name="contactNumber"
              value={storeDetails.contactNumber}
              onChange={handleChange}
              className="w-full border p-2"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-bold mb-2 text-purple-800"
            >
              Email
            </label>
            <input
              type="text"
              id="email"
              name="email"
              value={storeDetails.email}
              onChange={handleChange}
              className="w-full border p-2"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="googleAddress"
              className="block text-sm font-bold mb-2 text-purple-800"
            >
              Google Address
            </label>
            <input
              type="text"
              id="googleAddress"
              name="googleAddress"
              value={storeDetails.googleAddress}
              onChange={handleChange}
              className="w-full border p-2"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="openingTime"
              className="block text-sm font-bold mb-2 text-purple-800"
            >
              Opening Time
            </label>
            <select
              id="openingTime"
              name="openingTime"
              value={storeDetails.openingTime}
              onChange={handleChange}
              className="w-full border p-2"
            >
              <option value="" disabled>
                Select opening time
              </option>
              {timeOptions.map((time) => (
                <option key={time} value={time}>
                  {time}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label
              htmlFor="closingTime"
              className="block text-sm font-bold mb-2 text-purple-800"
            >
              Closing Time
            </label>
            <select
              id="closingTime"
              name="closingTime"
              value={storeDetails.closingTime}
              onChange={handleChange}
              className="w-full border p-2"
            >
              <option value="" disabled>
                Select closing time
              </option>
              {timeOptions.map((time) => (
                <option key={time} value={time}>
                  {time}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label
              htmlFor="socialHandle"
              className="block text-sm font-bold mb-2 text-purple-800"
            >
              Social Handle
            </label>
            <input
              type="text"
              id="socialHandle"
              name="socialHandle"
              value={storeDetails.socialHandle}
              onChange={handleChange}
              className="w-full border p-2"
            />
          </div>
          <button
            type="submit"
            className="bg-purple-800 text-white px-4 py-2 rounded-md hover:bg-purple-700"
          >
            Add Store
          </button>
        </form>
      </div>
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-purple-800 mb-4">
          Existing Stores
        </h2>
        <ul className="list-disc pl-4">
          {existingStores.map((store) => (
            <li
              key={store.id}
              onClick={() => handleClickStore(store)}
              className="cursor-pointer text-blue-500 hover:underline"
            >
              {store.name} - {store.categories}
            </li>
          ))}
        </ul>
      </div>
      {storeDetails.name && (
        <div>
          <h2 className="text-2xl font-bold text-purple-800 mb-4">
            Selected Store Details
          </h2>
          <ul className="list-disc pl-4">
            {Object.entries(storeDetails).map(([key, value]) => (
              <li key={key}>
                <strong className="font-bold">{key}:</strong> {value}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AddStore;
