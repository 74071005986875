import React from "react";
import ProductsList from "../components/ProductComponents/ProductsList";

const Products = () => {
  return (
    <div>
      <ProductsList />
      {/* <Product /> */}
    </div>
  );
};

export default Products;
