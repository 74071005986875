import React, { useState } from "react";

const ManageCoupons = () => {
  const [couponDetails, setCouponDetails] = useState({
    couponCode: "",
    discountPercentage: "",
    expiryDate: "",
  });

  const [existingCoupons, setExistingCoupons] = useState([
    {
      id: 1,
      couponCode: "SALE10",
      discountPercentage: "10%",
      expiryDate: "2024-12-31",
    },
    {
      id: 2,
      couponCode: "FLASH20",
      discountPercentage: "20%",
      expiryDate: "2024-09-30",
    },
    // Add more existing coupons as needed
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCouponDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      couponDetails.couponCode &&
      couponDetails.discountPercentage &&
      couponDetails.expiryDate
    ) {
      const newCoupon = {
        id: existingCoupons.length + 1,
        couponCode: couponDetails.couponCode,
        discountPercentage: couponDetails.discountPercentage,
        expiryDate: couponDetails.expiryDate,
      };

      setExistingCoupons((prevCoupons) => [...prevCoupons, newCoupon]);

      setCouponDetails({
        couponCode: "",
        discountPercentage: "",
        expiryDate: "",
      });
    } else {
      alert("Please fill in all the coupon details.");
    }
  };

  const handleDelete = (id) => {
    setExistingCoupons((prevCoupons) =>
      prevCoupons.filter((coupon) => coupon.id !== id)
    );
  };

  return (
    <div className="text-black p-8">
      <div className="mb-6">
        <h1 className="text-3xl font-bold text-purple-800 mb-2">
          Manage Coupons
        </h1>
        <form onSubmit={handleSubmit} className="w-full max-w-lg">
          <div className="mb-4">
            <label
              className="block text-sm font-bold mb-2 text-purple-800"
              htmlFor="couponCode"
            >
              Coupon Code
            </label>
            <input
              type="text"
              id="couponCode"
              name="couponCode"
              value={couponDetails.couponCode}
              onChange={handleChange}
              className="w-full border p-2"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-bold mb-2 text-purple-800"
              htmlFor="discountPercentage"
            >
              Discount Percentage
            </label>
            <input
              type="text"
              id="discountPercentage"
              name="discountPercentage"
              value={couponDetails.discountPercentage}
              onChange={handleChange}
              className="w-full border p-2"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-bold mb-2 text-purple-800"
              htmlFor="expiryDate"
            >
              Expiry Date
            </label>
            <input
              type="date"
              id="expiryDate"
              name="expiryDate"
              value={couponDetails.expiryDate}
              onChange={handleChange}
              className="w-full border p-2"
            />
          </div>
          <button
            type="submit"
            className="bg-purple-800 text-white px-4 py-2 rounded-md hover:bg-purple-700"
          >
            Create Coupon
          </button>
        </form>
      </div>
      <div className="mb-6">
        <h2 className="text-xl font-bold text-purple-800 mb-2">
          Existing Coupons
        </h2>
        <table className="w-full border">
          <thead>
            <tr>
              <th className="border p-2">Coupon Code</th>
              <th className="border p-2">Discount Percentage</th>
              <th className="border p-2">Expiry Date</th>
              <th className="border p-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {existingCoupons.map((coupon) => (
              <tr key={coupon.id}>
                <td className="border p-2">{coupon.couponCode}</td>
                <td className="border p-2">{coupon.discountPercentage}</td>
                <td className="border p-2">{coupon.expiryDate}</td>
                <td className="border p-2">
                  <button
                    onClick={() => handleDelete(coupon.id)}
                    className="bg-red-500 text-white px-2 py-1 rounded-md hover:bg-red-400"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageCoupons;
