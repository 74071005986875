import "./assets/libs/boxicons-2.1.1/css/boxicons.min.css";
import "./scss/App.scss";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import MainLayout from "./layout/MainLayout";
import Products from "./pages/Products";
import "./index.css";
import OrderList from "./pages/OrderList";
import CustomerList from "./pages/CustomerList";
import EditStore from "./pages/EditStore";
import Manage from "./pages/Manage";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import Login from "./pages/Login";

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoutes>
            <MainLayout />
          </ProtectedRoutes>
        }
      >
        <Route
          index
          element={
            <ProtectedRoutes>
              <Dashboard />
            </ProtectedRoutes>
          }
        />
        <Route
          path="orders"
          element={
            <ProtectedRoutes>
              <OrderList />{" "}
            </ProtectedRoutes>
          }
        />
        <Route
          path="products"
          element={
            <ProtectedRoutes>
              <Products />{" "}
            </ProtectedRoutes>
          }
        />
        <Route
          path="customers"
          element={
            <ProtectedRoutes>
              <CustomerList />{" "}
            </ProtectedRoutes>
          }
        />
        <Route
          path="settings"
          element={
            <ProtectedRoutes>
              <EditStore />{" "}
            </ProtectedRoutes>
          }
        />
        <Route
          path="manage"
          element={
            <ProtectedRoutes>
              <Manage />{" "}
            </ProtectedRoutes>
          }
        />
      </Route>

      <Route path="login" element={<Login />} />
    </Routes>
  );
}

export default App;
