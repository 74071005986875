import React, { useState } from "react";
const EditStore = () => {
  const [storeDetails, setStoreDetails] = useState({
    storeName: "RK Sarees",
    address: "4/56-2 22no. phatak, Patiala, Punjab",
    contactNumber: "9876543210",
    email: "rksarees@notatmrp.com",
    openingHours: "Mon-Fri: 9 AM - 7 PM, Sat-Sun: 10 AM - 5 PM",
  });
  const [editing, setEditing] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setStoreDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Store details updated:", storeDetails);
    setEditing(false);
  };
  return (
    <div className="text-black p-8">
      <div className="mb-6">
        <h1 className="text-3xl font-bold text-purple-800 mb-2">
          Store Information
        </h1>
        <div className="bg-white text-black p-4 rounded-md">
          <p>
            <strong>Store Name:</strong> {storeDetails.storeName}
          </p>
          <p>
            <strong>Address:</strong> {storeDetails.address}
          </p>
          <p>
            <strong>Contact Number:</strong> {storeDetails.contactNumber}
          </p>
          <p>
            <strong>Email:</strong> {storeDetails.email}
          </p>
          <p>
            <strong>Opening Hours:</strong> {storeDetails.openingHours}
          </p>
          <button
            onClick={() => setEditing(true)}
            className="bg-purple-800 text-white px-2 py-1 rounded-md mt-2 hover:bg-purple-700"
          >
            Edit Details
          </button>
        </div>
      </div>
      {editing && (
        <form onSubmit={handleSubmit} className="w-full max-w-lg">
          <div className="mb-4">
            <label
              className="block text-sm font-bold mb-2 text-purple-800"
              htmlFor="storeName"
            >
              Store Name
            </label>
            <input
              type="text"
              id="storeName"
              name="storeName"
              value={storeDetails.storeName}
              onChange={handleChange}
              className="w-full border p-2"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-bold mb-2 text-purple-800"
              htmlFor="address"
            >
              Address
            </label>
            <input
              type="text"
              id="address"
              name="address"
              value={storeDetails.address}
              onChange={handleChange}
              className="w-full border p-2"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-bold mb-2 text-purple-800"
              htmlFor="contactNumber"
            >
              Contact Number
            </label>
            <input
              type="tel"
              id="contactNumber"
              name="contactNumber"
              value={storeDetails.contactNumber}
              onChange={handleChange}
              className="w-full border p-2"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-bold mb-2 text-purple-800"
              htmlFor="email"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={storeDetails.email}
              onChange={handleChange}
              className="w-full border p-2"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-bold mb-2 text-purple-800"
              htmlFor="openingHours"
            >
              Opening Hours
            </label>
            <textarea
              id="openingHours"
              name="openingHours"
              value={storeDetails.openingHours}
              onChange={handleChange}
              className="w-full border p-2"
            />
          </div>
          <button
            type="submit"
            className="bg-purple-800 text-white px-4 py-2 rounded-md hover:bg-purple-700"
          >
            Save Changes
          </button>
        </form>
      )}
    </div>
  );
};

export default EditStore;
