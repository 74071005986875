import React, { useState } from "react";
import "./ProductList.css";
import saree1 from "../../assets/images/s1.jpeg";
import saree2 from "../../assets/images/s2.jpg";
import saree3 from "../../assets/images/s3.png";
import saree4 from "../../assets/images/s4.jpg";

const ProductsList = () => {
  const [products, setProducts] = useState([
    {
      id: 1,
      title: "Elegant Silk Item",
      description: "Beautiful silk item with intricate design.",
      price: "₹2,999",
      imageUrl: saree1,
    },
    {
      id: 2,
      title: "Cotton Handloom Item",
      description: "Comfortable cotton handloom item for everyday wear.",
      price: "₹1,499",
      imageUrl: saree2,
    },
    {
      id: 3,
      title: "Banarasi Silk Item",
      description: "Classic Banarasi silk item for special occasions.",
      price: "₹4,599",
      imageUrl: saree3,
    },
    {
      id: 4,
      title: "Georgette Party Item",
      description: "Stylish georgette item perfect for parties.",
      price: "₹3,799",
      imageUrl: saree4,
    },
  ]);

  const [newProductTitle, setNewProductTitle] = useState("");
  const [newProductDescription, setNewProductDescription] = useState("");
  const [newProductPrice, setNewProductPrice] = useState("");
  const [newProductImageUrl, setNewProductImageUrl] = useState("");

  const addProduct = () => {
    const newItem = {
      id: products.length + 1,
      title: newProductTitle,
      description: newProductDescription,
      price: `₹${newProductPrice}`,
      imageUrl: newProductImageUrl,
    };
    setProducts([...products, newItem]);
    setNewProductTitle("");
    setNewProductDescription("");
    setNewProductPrice("");
    setNewProductImageUrl("");
  };

  const deleteProduct = (productId) => {
    const updatedProducts = products.filter(
      (product) => product.id !== productId
    );
    setProducts(updatedProducts);
  };

  return (
    <div className="bg-white text-black p-8">
      <h1 className="text-3xl font-bold mb-6 text-purple-800">
        Item Collection
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-6">
        {products.map((product) => (
          <div key={product.id} className="product-card">
            <div
              className="bg-white text-purple-800 p-4 rounded-md shadow-md mb-4 border border-gray-500"
              style={{ minHeight: "200px" }}
            >
              <h2 className="text-xl font-semibold mb-2">{product.title}</h2>
              <img
                src={product.imageUrl}
                alt={product.title}
                className="product-images mb-4 rounded-md w-full h-48 object-cover"
              />
              <p className="text-purple-800">{product.description}</p>
              <p className="text-purple-800 font-semibold mt-2">
                {product.price}
              </p>
              <button
                onClick={() => deleteProduct(product.id)}
                className="bg-red-500 text-white px-2 py-1 rounded-md mt-2"
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="bg-purple-800 text-black p-4 rounded-md shadow-md mt-6">
        <h1 className="text-2xl font-semibold mb-2 text-white">Add New Item</h1>
        <div className="mb-4">
          <label
            className="block text-white text-sm font-bold mb-2"
            htmlFor="title"
          >
            Item Title:
          </label>
          <input
            type="text"
            id="title"
            value={newProductTitle}
            onChange={(e) => setNewProductTitle(e.target.value)}
            className="border p-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-white text-sm font-bold mb-2"
            htmlFor="description"
          >
            Item Description:
          </label>
          <input
            type="text"
            id="description"
            value={newProductDescription}
            onChange={(e) => setNewProductDescription(e.target.value)}
            className="border p-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-white text-sm font-bold mb-2"
            htmlFor="price"
          >
            Item Price (₹):
          </label>
          <input
            type="text"
            id="price"
            value={newProductPrice}
            onChange={(e) => setNewProductPrice(e.target.value)}
            className="border p-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-white text-sm font-bold mb-2"
            htmlFor="imageUrl"
          >
            Image URL:
          </label>
          <input
            type="text"
            id="imageUrl"
            value={newProductImageUrl}
            onChange={(e) => setNewProductImageUrl(e.target.value)}
            className="border p-2 w-full"
          />
        </div>
        <button
          onClick={addProduct}
          className="bg-white text-purple-800 px-4 py-2 rounded-md hover:bg-purple-700"
        >
          Add Item
        </button>
      </div>
    </div>
  );
};
export default ProductsList;
