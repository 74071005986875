import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"; // Change this import
import axios from "axios";
import Cookies from "js-cookie";
import { useToast } from "@chakra-ui/react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  // const [message, setMessage] = useState(""); // State for error message

  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is already logged in
    const isUserLoggedIn = Cookies.get("notatmrpsellertoken");
    if (isUserLoggedIn) {
      navigate("/");
    }
  }, []); // Empty dependency array ensures this effect runs only once, similar to componentDidMount

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const data = { email, password };
      const response = await axios.post(
        "https://api.notatmrp.in/api/login",
        data
      );

      if (response.status === 200) {
        // Check if the user has the 'retail' role
        const userRole = response.data.user.role;

        if (userRole === "retail" || userRole === "admin") {
          // Set the token in cookies
          Cookies.set("notatmrpsellertoken", response.data.token, {
            expires: 30,
          });

          toast({
            title: "Login Successful",
            description: "You have logged in successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });

          setTimeout(() => {
            // Redirect the user to the appropriate page based on their role
            navigate("/"); // Change '/dashboard' to the retail-specific page
            window.location.reload();
          }, 1000);
        } else {
          // Display an error message for users without the 'retail' role
          toast({
            title: "Error",
            description: "You do not have the required role to log in.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });

          // Log the user out if the role is not 'retail'
          Cookies.remove("notatmrpsellertoken");

          // Redirect the user back to the login page
          navigate("/login");
        }
      }
    } catch (error) {
      let errorMessage =
        "An unexpected error occurred. Please try again later.";

      if (error.response) {
        switch (error.response.status) {
          case 401:
            errorMessage = "Email or Password is incorrect.";
            break;
          case 400:
            // Add more detailed error messages here if needed
            errorMessage = "Invalid credentials.";
            break;
          case 403: // Forbidden access
            errorMessage = "You do not have the required role to log in.";
            // Log the user out if the role is not 'retail'
            Cookies.remove("notatmrpsellertoken");
            // Redirect the user back to the login page
            navigate("/login");
            break;
          default:
            break;
        }
      } else {
        errorMessage = "Network error. Please check your connection.";
      }

      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <section className="m-5">
        <div className=" mx-auto mt-10">
          <h1 className="text-center font-serif m-5 text-3xl font-[400] ">
            {" "}
            Retailer Sign In
          </h1>
          <form
            onSubmit={handleSubmit}
            className="max-w-md mx-auto bg-white p-8 border rounded shadow-lg"
          >
            <div className="mb-6">
              {/* <label className="text-lg font-semibold mb-2">Email:</label> */}
              <input
                type="email"
                id="email"
                className="w-full p-3 rounded-md border  text-gray-800 focus:ring-2 focus:ring-purple-700"
                value={email}
                required
                placeholder="Enter Your Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="mb-6">
              {/* <label className="text-lg font-semibold mb-2">Password:</label> */}
              <input
                type="password"
                id="password"
                required
                className="w-full p-3 rounded-md border  text-gray-800 focus:ring-2 focus:ring-purple-700"
                value={password}
                placeholder="Enter Your Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="mb-6">
              <button
                type="button"
                // onClick={() => <Navigate to="#" />}
                className="text-purple-700 hover:underline"
                // Handle Forgot Password logic
              >
                Forgot Password?
              </button>
            </div>

            <button
              type="submit"
              id="submit"
              className="w-full p-3 rounded-[20px] text-white bg-purple-600 hover:bg-purple-700 focus:ring-2 focus:ring-purple-500"
              disabled={loading}
            >
              {loading ? "Logging in..." : "Login"}
            </button>
          </form>
          {/* {message && (
          <div className="mt-4 text-center">
            <div className="alert alert-danger" role="alert">
              {message}
            </div>
          </div>
        )} */}

          <p className="mt-4 text-center">
            Don't have an account?{" "}
            <Link
              to="https://notatmrp.com/add-a-business"
              // onClick={() => router.push("/register")}
              className="text-purple-700 hover:underline"
            >
              Join Now
            </Link>
          </p>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Login;
