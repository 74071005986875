import React, { useState } from "react";
import AddStore from "../components/Manage/AddStore";
import ManageCoupons from "../components/Manage/ManageCoupons";
import AddNotification from "../components/Manage/AddNotification";

const Manage = () => {
  const [activeComponent, setActiveComponent] = useState("addStore");

  const renderComponent = () => {
    switch (activeComponent) {
      case "addStore":
        return <AddStore />;
      case "manageCoupons":
        return <ManageCoupons />;
      case "addNotification":
        return <AddNotification />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="mb-4">
        <button
          onClick={() => setActiveComponent("addStore")}
          className="bg-purple-800 text-white px-4 py-2 rounded-md mr-2 hover:bg-purple-700"
        >
          Add Store
        </button>
        <button
          onClick={() => setActiveComponent("manageCoupons")}
          className="bg-purple-800 text-white px-4 py-2 rounded-md mr-2 hover:bg-purple-700"
        >
          Manage Coupons
        </button>
        <button
          onClick={() => setActiveComponent("addNotification")}
          className="bg-purple-800 text-white px-4 py-2 rounded-md hover:bg-purple-700"
        >
          Add Notification
        </button>
      </div>
      {renderComponent()}
    </div>
  );
};

export default Manage;
