import React, { useState } from "react";

const ExistingNotifications = ({ notifications, onDeleteNotification }) => {
  return (
    <div>
      <h2 className="text-xl font-bold text-purple-800 mb-2">
        Existing Notifications
      </h2>
      <table className="min-w-full">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Title</th>
            <th className="py-2 px-4 border-b">Message</th>
            <th className="py-2 px-4 border-b">Start Date</th>
            <th className="py-2 px-4 border-b">End Date</th>
            <th className="py-2 px-4 border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {notifications.map((notification) => (
            <tr key={notification.id}>
              <td className="py-2 px-4 border-b">{notification.title}</td>
              <td className="py-2 px-4 border-b">{notification.message}</td>
              <td className="py-2 px-4 border-b">{notification.startDate}</td>
              <td className="py-2 px-4 border-b">{notification.endDate}</td>
              <td className="py-2 px-4 border-b">
                <button
                  className="bg-red-500 text-white px-2 py-1 rounded-md mr-2"
                  onClick={() => onDeleteNotification(notification.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const AddNotification = () => {
  const [notificationDetails, setNotificationDetails] = useState({
    title: "",
    message: "",
    startDate: "",
    endDate: "",
  });

  const [existingNotifications, setExistingNotifications] = useState([
    {
      id: 1,
      title: "Special Sale",
      message: "Enjoy 20% off on all fashion items!",
      startDate: "2024-02-10",
      endDate: "2024-02-20",
    },
    {
      id: 2,
      title: "Weekend Offer",
      message: "Get a free dessert with every main course this weekend!",
      startDate: "2024-02-15",
      endDate: "2024-02-17",
    },
  ]);

  const handleDeleteNotification = (notificationId) => {
    const updatedNotifications = existingNotifications.filter(
      (notification) => notification.id !== notificationId
    );
    setExistingNotifications(updatedNotifications);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newNotification = {
      id: existingNotifications.length + 1,
      ...notificationDetails,
    };
    setExistingNotifications([...existingNotifications, newNotification]);
    setNotificationDetails({
      title: "",
      message: "",
      startDate: "",
      endDate: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNotificationDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  return (
    <div className="text-black p-8">
      <div className="mb-6">
        <h1 className="text-3xl font-bold text-purple-800 mb-2">
          Add Notification
        </h1>
        <form onSubmit={handleSubmit} className="w-full max-w-lg">
          <div className="mb-4">
            <label
              className="block text-sm font-bold mb-2 text-purple-800"
              htmlFor="title"
            >
              Title
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={notificationDetails.title}
              onChange={handleChange}
              className="w-full border p-2"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-bold mb-2 text-purple-800"
              htmlFor="message"
            >
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={notificationDetails.message}
              onChange={handleChange}
              className="w-full border p-2"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-bold mb-2 text-purple-800"
              htmlFor="startDate"
            >
              Start Date
            </label>
            <input
              type="text"
              id="startDate"
              name="startDate"
              value={notificationDetails.startDate}
              onChange={handleChange}
              className="w-full border p-2"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-sm font-bold mb-2 text-purple-800"
              htmlFor="endDate"
            >
              End Date
            </label>
            <input
              type="text"
              id="endDate"
              name="endDate"
              value={notificationDetails.endDate}
              onChange={handleChange}
              className="w-full border p-2"
            />
          </div>
          <button
            type="submit"
            className="bg-purple-800 text-white px-4 py-2 rounded-md hover:bg-purple-700"
          >
            Add Notification
          </button>
        </form>
      </div>
      <ExistingNotifications
        notifications={existingNotifications}
        onDeleteNotification={handleDeleteNotification}
      />
    </div>
  );
};

export default AddNotification;
