import React from "react";
import logo from "../../assets/images/logoimg.png";
export default function Navbar() {
  return (
    <div className="border-[#7415A2] p-4 md:p-3 bg-[#7415A2] text-[#ffffff]">
      <div className="text-center">
        <img
          src={logo}
          alt="Not@Mrp Logo"
          className="cursor-pointer w-[200px] h-[60px] object-contain"
        />
      </div>
    </div>
  );
}
