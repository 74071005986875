import React, { useState } from "react";

const OrderList = () => {
  const [orders, setOrders] = useState([
    {
      id: 1,
      product: "Elegant Silk Item",
      price: "₹2,999",
      quantity: 2,
      total: "₹5,998",
      user: {
        name: "Saksham Raghuvanshi",
        address: "123 Pathankot,Punjab",
        phoneNumber: "8239429311",
      },
    },
    {
      id: 2,
      product: "Cotton Handloom Item",
      price: "₹1,499",
      quantity: 1,
      total: "₹1,499",
      user: {
        name: "Aditya Sharma",
        address: "Hno-15, Sector 22, Delhi",
        phoneNumber: "9983324203",
      },
    },
    {
      id: 3,
      product: "Traditional Saree",
      price: "₹3,499",
      quantity: 1,
      total: "₹3,499",
      user: {
        name: "Sharath Chandra",
        address: "456 Ahmedabad, Gujarat",
        phoneNumber: "7890123456",
      },
    },
    {
      id: 4,
      product: "Handcrafted Kurti",
      price: "₹1,799",
      quantity: 3,
      total: "₹5,397",
      user: {
        name: "Prashika Sawant",
        address: "789 Jaipur, Rajasthan",
        phoneNumber: "8765432109",
      },
    },
  ]);

  const [selectedOrderDetails, setSelectedOrderDetails] = useState(null);

  const showOrderDetails = (orderId) => {
    const selectedOrder = orders.find((order) => order.id === orderId);
    setSelectedOrderDetails(selectedOrder);
  };

  const closeOrderDetails = () => {
    setSelectedOrderDetails(null);
  };

  return (
    <div className="bg-white text-black p-8">
      <h1 className="text-3xl font-bold mb-6">Order List</h1>
      <table className="w-full border">
        <thead>
          <tr className="bg-purple-800 text-white">
            <th className="border p-2">Order ID</th>
            <th className="border p-2">Product</th>
            <th className="border p-2">Price</th>
            <th className="border p-2">Quantity</th>
            <th className="border p-2">Total</th>
            <th className="border p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.id} className="bg-purple text-black">
              <td className="border p-2">{order.id}</td>
              <td className="border p-2">{order.product}</td>
              <td className="border p-2">{order.price}</td>
              <td className="border p-2">{order.quantity}</td>
              <td className="border p-2">{order.total}</td>
              <td className="border p-2">
                <button
                  onClick={() => showOrderDetails(order.id)}
                  className="bg-purple-900 text-white px-2 py-1 rounded-md"
                >
                  View Details
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedOrderDetails && (
        <div className="mt-4 p-4 border bg-gray-100">
          <h2 className="text-lg font-bold mb-2">Order Details</h2>
          <p>
            <strong>User Name:</strong> {selectedOrderDetails.user.name}
          </p>
          <p>
            <strong>User Address:</strong> {selectedOrderDetails.user.address}
          </p>
          <p>
            <strong>User Phone Number:</strong>{" "}
            {selectedOrderDetails.user.phoneNumber}
          </p>
          <button
            onClick={closeOrderDetails}
            className="bg-purple-900 text-white px-2 py-1 rounded-md mt-2"
          >
            Close Details
          </button>
        </div>
      )}
    </div>
  );
};

export default OrderList;
